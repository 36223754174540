// So, IE 8 apparently does not support getOwnPropertyNames, adding in this shim
if (typeof Object.getOwnPropertyNames !== "function") {
    Object.getOwnPropertyNames = function (obj) {
        var keys = [];

        // Only iterate the keys if we were given an object, and
        // a special check for null, as typeof null == "object"
        if (typeof obj === "object" && obj !== null) {
            // Use a standard for in loop
            for (var x in obj) {
                // A for in will iterate over members on the prototype
                // chain as well, but Object.getOwnPropertyNames returns
                // only those directly on the object, so use hasOwnProperty.
                if (obj.hasOwnProperty(x)) {
                    keys.push(x);
                }
            }
        }

        return keys;
    };
}

if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function(elt /*, from*/) {
        var len = this.length >>> 0;

        var from = Number(arguments[1]) || 0;
        from = (from < 0) ? Math.ceil(from) : Math.floor(from);
        if (from < 0) {
            from += len;
        }

        for (; from < len; from++)
        {
            if (from in this && this[from] === elt) {
                return from;
            }
        }
        return -1;
  };
}