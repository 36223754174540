/* jshint undef: true, browser: true, unused: true, indent: 4 */
/* exported SiteUtilities */


var SiteUtilities = {

    gaTrackPage: function(googleAnalytics, pageUrl) {
        if (typeof googleAnalytics == 'undefined' ||
            typeof pageUrl == 'undefined') {
            return false;
        }
        googleAnalytics('set', 'page', pageUrl);
        googleAnalytics('send', 'pageview');
    },

    determineDateOfBirthYearFromAge: function(age) {
        if (!parseInt(age, 10)) {
            return false;
        }

        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        return currentYear - age;
    },

    convertNumberToString: function(dataToConvert) {
        if (!parseInt(dataToConvert, 10)) {
            return false;
        }
        var numberToString = new Array('', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth');
        return numberToString[dataToConvert];
    },

    validateAge: function(age, minAllowed, maxAllowed) {
        // All arguments must be integers; if not, fail
        if (!parseInt(age, 10) ||
            !parseInt(minAllowed, 10) ||
            !parseInt(maxAllowed, 10)) {
            return false;
        }

        if (age >= minAllowed && age <= maxAllowed) {
            return true;
        } else {
            return false;
        }
    }

};