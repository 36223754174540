/* jshint undef: true, browser: true, unused: true, indent: 4, jquery: true */
/* global SiteUtilities, gaAccountId:true, gtag:true, ssdFormPostPauseOverride */
/* global usePopunder:true, popUnderCallback:true */
/* exported adnShowCityStateWithError, adnSuccessCallback, adnInvalidCallback, setIgnoreSsdResponse */

var formBackupPostTimeoutId = 0;
var allowAddOnFormPost = true;
var ignoreSsdResponse = true;
var ssdFormPostPause = 5000;

if (
    typeof popUnderCallback === 'undefined' ||
    typeof usePopunder === 'undefined'
) {
    popUnderCallback = false;
    usePopunder = false;
}

if (typeof ssdFormPostPauseOverride !== 'undefined') {
    ssdFormPostPause = parseInt(ssdFormPostPauseOverride, 10);
}

var setPopunderOnClick = function(avtResponse) {
    if (avtResponse === 'success') {
        $('#step4-button-continue').attr('onclick', adnSuccessCallback);
        $('#step4-button-nothanks').attr('onclick', adnSuccessCallback);
    } else {
        $('#step4-button-continue').attr('onclick', adnInvalidCallback);
        $('#step4-button-nothanks').attr('onclick', adnInvalidCallback);
    }
};

var adnSuccessCallback = function() {
    window.location = '/thankyou';
};

var adnInvalidCallback = function() {
    window.location = '/pending';
};

var setIgnoreSsdResponse = function(value) {
    if (typeof value === 'boolean') {
        ignoreSsdResponse = value;
    }
};

var updateFormValues = function(nextStep) {
    $('#nssd-form').attr('data-step', nextStep);
};

var determineSuccessResponse = function() {
    var response = 'invalid';
    var ageValue = parseInt($('#currentage :selected').val(), 10);

    if (
        ageValue >= 50 &&
        ageValue <= 63 &&
        $('#p_payroll_select :selected').val() === 'Yes' &&
        $('#p_alreadyreceivingssdibenefits_select :selected').val() === 'No' &&
        $('#p_attorneyforssd_select :selected').val() === 'No' &&
        $('#p_doctorverificationp3_select :selected').val() === 'Yes'
    ) {
        response = 'success';
    }

    return response;
};

var processFinal = function() {
    var avtResponse = $('input#avt_lead_response').val();

    if (ignoreSsdResponse === true) {
        avtResponse = determineSuccessResponse();
    }

    if (usePopunder === true && typeof popUnderCallback === 'function') {
        popUnderCallback();
        return;
    }

    if (avtResponse === 'success') {
        adnSuccessCallback();
        return true;
    } else {
        adnInvalidCallback();
        return false;
    }
};

var showNextForm = function(currentStep, maxStep) {
    // Show next form
    var hideoptions = { direction: 'left', mode: 'hide' };
    var nextStep = parseInt(currentStep, 10) + 1;

    var currentStepElement =
        '#nssd-form #mstep-' +
        SiteUtilities.convertNumberToString(currentStep) +
        '-step';
    var nextStepElement =
        '#nssd-form #mstep-' +
        SiteUtilities.convertNumberToString(nextStep) +
        '-step';
    gtag('config', gaAccountId, {
        page_path: '/step-' + SiteUtilities.convertNumberToString(nextStep)
    });

    var currentLiElement = '#step' + currentStep + '-li';
    var nextLiElement = '#step' + nextStep + '-li';
    // 4 steps, 3 buttons
    if (nextStep > 3) {
        nextLiElement = '#step3-li';
    }

    // On next step, need to validate hidden form elements
    if (nextStep === 4) {
        $('#nssd-form').validationEngine('attach', {
            autoPositionUpdate: false,
            showOneMessage: true,
            focusFirstField: true,
            scroll: true,
            showArrow: false,
            addFailureCssClassToField: 'inputError',
            validateNonVisibleFields: true,
            promptPosition: 'bottomLeft'
        });
    }

    if (nextStep > maxStep) {
        processFinal();
        return 0;
    } else {
        $(currentStepElement).effect('slide', hideoptions, function() {
            $(nextStepElement).fadeIn();
            $(currentLiElement).removeClass('current');
            $(nextLiElement).addClass('current');
        });
    }

    return nextStep;
};

var getData = function(dataSource) {
    var obj = {};
    // gather data matching passed-in data source
    var dataClass = '.' + dataSource;

    $(dataClass).each(function(i, el) {
        el = $(el);
        if (el.attr('type') === 'radio' || el.attr('type') === 'checkbox') {
            if (!el.is(':checked')) {
                return;
            }
        }
        obj[el.attr('name')] = el.val();
    });

    return obj;
};

var gatherFormData = function(dataSource) {
    dataSource = dataSource || 'ssd-data';
    // ALL forms need some basic info, including contact info
    var formData = {};

    var data = getData('common-data');
    $.extend(formData, data);

    // There are couple of fields that need to be added manually
    var commonDataKeys = {
        'address[state]': $('#residenceStateId').val(),
        'address[city]': $('#residenceCity').val(),
        'address[postal_code]': $('#zipCode').val()
    };

    for (var key in commonDataKeys) {
        formData[key] = commonDataKeys[key];
    }

    // gather data matching passed-in data source
    // reset data to empty object JIC
    data = {};
    data = getData(dataSource);

    // merge found data with existing formData
    $.extend(formData, data);

    if (dataSource === 'ssd-data') {
        formData._campaign_key = $('#ssd-campaign-key').val();
    } else if (dataSource === 'personalinjury-data') {
        formData._campaign_key = $('#pi-campaign-key').val();
        formData.situation = $('#pi-description').val();
        formData.injured_person_name =
            $('#first_name').val() + ' ' + $('#last_name').val();
        formData.phone =
            $('.phone-data-areacode').val() +
            $('.phone-data-prefix').val() +
            $('.phone-data-suffix').val();
        formData.sid = $('#pi-sid').val();
        formData.pid = $('#pi-pid').val();
    } else if (dataSource === 'offer-2-data') {
        formData._campaign_key = $('#offer-2-campaign-key').val();
        formData.sid = $('#offer-2-sid').val();
        formData.pid = $('#offer-2-pid').val();
    } else if (dataSource === 'offer-3-data') {
        formData._campaign_key = $('#offer-3-campaign-key').val();
        formData.sid = $('#offer-3-sid').val();
        formData.pid = $('#offer-3-pid').val();
        formData.xxTrustedFormCertUrl = $('#xxTrustedFormCertUrl_0').val();
        formData.phone =
            $('.phone-data-areacode').val() +
            $('.phone-data-prefix').val() +
            $('.phone-data-suffix').val();
        formData.dob =
            $('#p_dob-year').val() +
            '-' +
            $('#p_dob-month').val() +
            '-' +
            $('#p_dob-day').val();
    }
    return formData;
};

var processResponse = function(currentStep, maxStep) {
    var nextStep = showNextForm(currentStep, maxStep);
    if (nextStep > 0) {
        updateFormValues(nextStep);
    }
};

var postForm = function(dataSource, step, maxStep) {
    step = step || 0;
    maxStep = maxStep || 0;

    $.ajax({
        url: $('#nssd-form').attr('action'),
        type: 'POST',
        dataType: 'html',
        data: gatherFormData(dataSource),
        success: function(data) {
            if (dataSource === 'ssd-data') {
                $('#pixelit').html(data);
            }
            return true;
        },
        error: function() {
            return true;
        },
        complete: function() {
            if (dataSource === 'ssd-data' && ignoreSsdResponse === false) {
                processResponse(step, maxStep);
            }
            if (ignoreSsdResponse === false && usePopunder === true) {
                var avtResponse = $('input#avt_lead_response').val();
                setPopunderOnClick(avtResponse);
            }
        }
    });
};

var submitAddOnForm = function(step, maxStep) {
    // Wait time for posting leads and/or submitting events to GA
    var timeToPost = 0;

    if ($('#offer-2-form').length > 0) {
        // If interested in second offer
        if ($('#offer_2_interest_yes').prop('checked') === true) {
            timeToPost += 1200;
            postForm('offer-2-data');
            gtag('event', 'Offer 2 Interest', {
                event_category: 'Secondary Offer',
                event_label: 'Yes'
            });
        } else if ($('#offer_2_interest_no').prop('checked') === true) {
            timeToPost += 200;
            gtag('event', 'Offer 2 Interest', {
                event_category: 'Secondary Offer',
                event_label: 'Not Interested'
            });
        } else {
            timeToPost += 200;
            gtag('event', 'Offer 2 Interest', {
                event_category: 'Secondary Offer',
                event_label: 'No Choice Made'
            });
        }
    }

    if ($('#pi-form').length > 0) {
        // Personal Injury offer
        if ($('#injured_accident_wantcontact_yes').prop('checked') === true) {
            timeToPost += 1200;
            postForm('personalinjury-data');
            gtag('event', 'PI Interest', {
                event_category: 'Secondary Offer',
                event_label: 'Yes'
            });
        } else if (
            $('#injured_accident_wantcontact_no').prop('checked') === true
        ) {
            timeToPost += 200;
            gtag('event', 'PI Interest', {
                event_category: 'Secondary Offer',
                event_label: 'No Contact'
            });
        } else if ($('#injured_accident_no').prop('checked') === true) {
            timeToPost += 200;
            gtag('event', 'PI Interest', {
                event_category: 'Secondary Offer',
                event_label: 'Not Injured in Past 3 years'
            });
        } else {
            timeToPost += 200;
            gtag('event', 'Secondary Offer', {
                event_category: 'PI Interest',
                event_label: 'No Choice Made'
            });
        }
    }

    // If there's an offer 3..
    if ($('#offer-3-form').length > 0) {
        // If interested in 3rd offer
        if ($('#offer_3_interest_yes').prop('checked') === true) {
            timeToPost += 1200;
            postForm('offer-3-data');
            gtag('event', 'Offer 3 Interest', {
                event_category: 'Secondary Offer',
                event_label: 'Yes'
            });
        } else if ($('#offer_3_interest_no').prop('checked') === true) {
            timeToPost += 200;
            gtag('event', 'Offer 3 Interest', {
                event_category: 'Secondary Offer',
                event_label: 'No Selected'
            });
        } else if ($('#offer_3_no').prop('checked') === true) {
            timeToPost += 200;
            gtag('event', 'Offer 3 Interest', {
                event_category: 'Secondary Offer',
                event_label: 'No Selected for Pre-Question'
            });
        } else {
            timeToPost += 200;
            gtag('event', 'Offer 3 Interest', {
                event_category: 'Secondary Offer',
                event_label: 'No Choice Made'
            });
        }
    }

    setTimeout(
        function() {
            processResponse(step, maxStep);
        }.bind(step, maxStep),
        timeToPost
    );
};

var enableAdditionalRequiredFields = function(type) {
    switch (type) {
        case 'pi':
            $('.personalinjury-required').addClass('validate[required]');
            break;
        case 'offer-2':
            $('.offer-2-required').addClass('validate[required]');
            break;
        case 'offer-3':
            $('.offer-3-required').addClass('validate[required]');
            break;
    }
};

var disableAdditionalRequiredFields = function(type) {
    switch (type) {
        case 'pi':
            $('#injured_accident_wantcontact_yes').prop('checked', false);
            $('.personalinjury-required').removeClass('validate[required]');
            break;
        case 'offer-2':
            $('#offer_2_interest_yes').prop('checked', false);
            $('.offer-2-required').removeClass('validate[required]');
            break;
        case 'offer-3':
            $('#offer_3_interest_yes').prop('checked', false);
            $('.offer-3-required').removeClass('validate[required]');
    }
};

var processStep = function(step, maxStep) {
    // ensure step and maxStep are both ints
    step = parseInt(step, 10);
    maxStep = parseInt(maxStep, 10);

    if (step <= 2) {
        gtag('event', 'Form Post', {
            event_category: 'Step ' + step + ' submitted',
            event_label: 'form next step'
        });
        var nextStep = showNextForm(step, maxStep);
        if (nextStep > 0) {
            updateFormValues(nextStep);
        }
    } else if (step === 3) {
        // SSD post MUST complete before we move on
        // Unless we want to post the data and move along
        if (ignoreSsdResponse === true && usePopunder === true) {
            var avtResponse = determineSuccessResponse();
            setPopunderOnClick(avtResponse);
        }
        postForm('ssd-data', step, maxStep);
        gtag('event', 'Form Post', {
            event_category: 'Step ' + step + ' submitted',
            event_label: 'ssd-data'
        });
        if (ignoreSsdResponse === true) {
            setTimeout(
                function() {
                    processResponse(step, maxStep);
                }.bind(step, maxStep),
                ssdFormPostPause
            );
        }
    } else {
        // if gtag doesn't return in 4 seconds, submit form anyways
        formBackupPostTimeoutId = setTimeout(
            function() {
                submitAddOnForm(step, maxStep);
            }.bind(step, maxStep),
            4000
        );

        var calledBack = false;

        gtag('event', 'Form Post', {
            event_category: 'Step ' + step + ' submitted',
            event_label: 'final-post',
            event_callback: function() {
                if (calledBack === false) {
                    clearTimeout(formBackupPostTimeoutId);
                    submitAddOnForm(step, maxStep);
                    calledBack = true;
                }
            }
        });


    }
    return false;
};

$(document).ready(function() {
    // If Google Analytics code did not run, define an empty function so JS errors don't occur
    if (typeof gtag === 'undefined') {
        gtag = function() {};
    }

    $(
        '.phone-data-areacode, .phone-data-prefix, .phone-data-suffix'
    ).autotab_magic();

    $('#injured_accident_yes').click(function() {
        $('#pi-form').show();
        enableAdditionalRequiredFields('pi');
    });

    $('#injured_accident_no').click(function() {
        $('#pi-form').hide();
        disableAdditionalRequiredFields('pi');
    });

    $('#offer_2_yes').click(function() {
        $('#offer-2-form').show();
        enableAdditionalRequiredFields('offer-2');
    });

    $('#offer_2_no').click(function() {
        $('#offer-2-form').hide();
        disableAdditionalRequiredFields('offer-2');
        disableAdditionalRequiredFields('offer-3');
    });

    $('#offer_3_yes').click(function() {
        $('#offer-3-form').show();
        enableAdditionalRequiredFields('offer-3');
    });

    $('#offer_3_no').click(function() {
        $('#offer-3-form').hide();
        disableAdditionalRequiredFields('offer-3');
    });

    $('#p_attorneyforssd_select').on('change', function() {
        if ($(this).val() === 'Yes') {
            $('#attorney-name').show();
            $('#p_attorneyname').addClass('ssd-data');
        } else {
            $('#attorney-name').hide();
            $('#p_attorneyname').removeClass('ssd-data');
        }
    });

    $('#residenceStateAndZip').hide();

    $('#nssd-form').validationEngine('attach', {
        autoPositionUpdate: true,
        showOneMessage: true,
        focusFirstField: true,
        scroll: false,
        showArrow: false,
        addFailureCssClassToField: 'inputError',
        promptPosition: 'bottomLeft'
    });

    $('#mstep-second-step').hide();
    $('#mstep-third-step').hide();
    $('#mstep-fourth-step').hide();

    // Form submit handling
    $('#nssd-form').submit(function(submitEvent) {
        $.scrollTo('#steps');

        var step = $(this).attr('data-step') || 1;

        var thisButton = $('.step' + step + '-button');

        $(thisButton).html('<div class="processing-ellipses">Processing <img src="//d1fd9shixcplvx.cloudfront.net/sites/af5e01cf/img/processing-ellipses.gif"></div>');
        $(thisButton).attr('disabled', 'disabled');

        var maxStep = ($('#nssd-form').attr('data-maxstep')) ? $('#nssd-form').attr('data-maxstep') : 4;

        submitEvent.preventDefault();
        processStep(step, maxStep);
    });

    $('#step1-button').click(function(e) {
        if (!$('#nssd-form').validationEngine('validate')) {
            e.preventDefault();
            return false;
        }

        $('#nssd-form').submit();
        return false;
    });

    $('#step2-button').click(function(e) {
        if (!$('#nssd-form').validationEngine('validate')) {
            e.preventDefault();
            return false;
        }

        $('#nssd-form').submit();
        return false;
    });

    $('#step3-button').click(function(e) {
        // First, verify all inputs are set before we pop the modal
        e.preventDefault();

        if (!$('#nssd-form').validationEngine('validate')) {
            return false;
        }

        $('#form-header').hide();
        $('#alt-form-header').show();

        $('#nssd-form').submit();
        return false;
    });

    $('#step4-button-nothanks').click(function(e) {
        e.preventDefault();

        disableAdditionalRequiredFields('pi');
        disableAdditionalRequiredFields('offer-2');
        allowAddOnFormPost = false;
        $('#nssd-form').submit();
    });

    $('#step4-button-continue').click(function(e) {
        e.preventDefault();

        if (!$('#nssd-form').validationEngine('validate')) {
            return false;
        }
        $('#nssd-form').submit();
    });

    $('#currentage').change(function() {
        var currentAgeValue = $(this).val();
        if (parseInt(currentAgeValue, 10) > 49) {
            $('.data-age-hide').hide();
            $('#p_payroll_select')
                .val('Yes')
                .change();
            $('#p_work5of10_select')
                .val('Yes')
                .change();
            $('#p_alreadyreceivingssdibenefits_select')
                .val('No')
                .change();
            $('#p_attorneyforssd_select')
                .val('No')
                .change();
            $('#p_doctorverificationp3_select')
                .val('Yes')
                .change();
        } else {
            $('.data-age-hide').show();
        }

        var birthYear = SiteUtilities.determineDateOfBirthYearFromAge(
            currentAgeValue
        );
        $('#p_dob-year').val(birthYear);
    });

    $('#nssd-form li.horiz select').change(function() {
        // Get name of element, strip _select, set hidden element value with drop-down value
        var elementName = $(this).attr('name');
        var hiddenElementName = elementName.replace('_select', '');
        var hiddenElement = $('#' + hiddenElementName);
        if (typeof hiddenElement !== 'undefined') {
            hiddenElement.val($(this).val());
        }
    });

    // Remove the disabled status of step1-button only after the rest of JS is ready
    $('#step1-button').removeAttr('disabled');

    $('#offer-3-form').hide();

    // if current age as numeric value, trigger change to fire extra processes
    if (parseInt($('#currentage').val(), 10) > 0) {
        $('#currentage').trigger('change');
    }

    // trigger select change in case we have a prepop situation
    $('#nssd-form li.horiz select').trigger('change');

    // trigger optional forms in case the person uses their back button
    if ($('#offer_2_yes').prop('checked')) {
        $('#offer_2_yes').trigger('click');
    }

    if ($('#offer_3_yes').prop('checked')) {
        $('#offer_3_yes').trigger('click');
    }

    if ($('#injured_accident_yes').prop('checked')) {
        $('#injured_accident_yes').trigger('click');
    }
});

var adnShowCityStateWithError = function() {
    $('#residenceStateAndZip').css('display', 'block');
};
