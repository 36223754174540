/* jshint undef: true, browser: true, unused: true, indent: 4, jquery: true */
/* exported adnSuccessCallback, adnInvalidCallback */

var adnSuccessCallback = function() {
    window.location = '/ssdopt/thankyou';
};

var adnInvalidCallback = function() {
    window.location = '/ssdopt/pending';
};
