
/* jshint browser: true, undef: true, unused: true, indent: 4, jquery: true */
/* global ga, allowContinuation, Option, ADN_processSingleValidator, ADN_processDataTransformations, randomSeedData, displayVerifyResponseModal, showVerifyResponseModal, displaySorryPage, window, document */
/* exported processFinal, prepops, adnSetCityValue, adnSetStateValue */

var states = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};
var prepops = {};
var cityOptionValue = null;
var allowHideStateAndZipDD = true;
var postedToLeads = false;
var formBackupPost = null;

var populateStateList = function() {
    var myStateId = $('#residenceStateId');
    var options = "";
    if (myStateId.prop) {
        options = myStateId.prop('options');
    } else {
        options = myStateId.attr('options');
    }

    $.each(states, function(val, text) {
        options[options.length] = new Option(val, text);
    });

};

var setTimerForBackupPost = function() {
    formBackupPost = setTimeout(processPostToLeads, 2000);
};

var gaTrackEvent = function(category, action, label) {
    if (typeof ga !== 'undefined') {
        ga('send', 'event', category, action, label);
    }
};

var adnShowCityStateWithError = function() {

    // Should probably show the city/state fields now
    $('#residenceStateAndZip').css('display', 'inline-block');
    // And an error message to inform the user what happened
    $('#validationErrors').text('We couldn\'t locate your City/State from your zip code, please enter them manually');
    $('#validationErrors').css('display', 'block');
    allowHideStateAndZipDD = false;

};

var adnSetStateValue = function(value, el) {
    el.empty();
    el.append(new Option(value, getStateAbbr(value), true, true));
    el.prop('selected', true);
    $(this).attr('selected', 'selected');
};

var adnSetCityValue = function(value, el) {
    el.empty();
    el.append(new Option(value, value, true, true));
    el.select();
};

var getStateAbbr = function(state) {
    if (states[state]) {
        return states[state];
    } else {
        return state;
    }
};

var populateCityList = function(citydata) {
    var myCity = $('#residenceCity');
    myCity.removeAttr('disabled');
    myCity.empty();
    myCity.append(new Option('Please select', ''));
    if (citydata !== null) {
        var options = "";
        if (myCity.prop) {
            options = myCity.prop('options');
        } else {
            options = myCity.attr('options');
        }

        $.each(citydata, function(val, text) {
            options[options.length] = new Option(text, text);
        });

    }
};

var verifyResponseConf = {};

$('#residenceStateId').change(function() {
    var stateAbbr = $(this).val();
    var ajaxOptions = {
        url: '/citylookupbystate?state=' + stateAbbr,
        dataType: 'json',
        success: function(data) {
            populateCityList(data);
            // this stuff is when the zipcode is set and we are trying to determine whether or not a city has been matched
            if (cityOptionValue !== null) {
                if ($(cityOptionValue).length > 0) {
                    $(cityOptionValue).attr("selected", "selected");
                } else {
                    adnShowCityStateWithError();
                }
            } else {
                adnShowCityStateWithError();
            }
        },
        error: function() {
            gaTrackEvent('Zipcode', 'Error');
            adnShowCityStateWithError();
        }
    };
    $.ajax(ajaxOptions);
});


var calculateAge = function(year, month, day) {
    // calculate age
    var now = new Date();
    var birthday = new Date(year, month-1, day);
    var age = now.getFullYear() - birthday.getFullYear();
    var m = now.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && now.getDate() < birthday.getDate())) {
        age--;
    }

    return age;
};

// Sets up the data array to be posted to Leads for storage
var adnBuildLeadsPostData = function() {

    // defined in modal_question_retry
    if (verifyResponseConf !== 'undefined') {
        if (ADN_processSingleValidator(verifyResponseConf.dataTransformationValidators)) {
            ADN_processDataTransformations(verifyResponseConf.dataTransformation);
        }
    }

    var dataToPost = {};
    $('#main-form :input').each(function(i, el) {
        el = $(el);
        if (el.attr('type') == 'radio' || el.attr('type') == 'checkbox') {
            if (!el.is(':checked')) {
                return;
            }
        }
        dataToPost[el.attr('name')] = el.val();
    });

    dataToPost._campaign_key = determineCampaignKey(randomSeedData);

    return dataToPost;
};

var adnPostToLeads = function() {

    var modalConfig = {};

    // This gets the property names from an object that's passed in - if length is 0, assume object is not set
    if (Object.getOwnPropertyNames(verifyResponseConf).length === 0) {
        verifyResponseConf = displayVerifyResponseModal();
    }

    var postData = adnBuildLeadsPostData();
    if (verifyResponseConf !== false) {
        if (verifyResponseConf.hasPopped === false) {
            modalConfig = verifyResponseConf.modalConfig;
            showVerifyResponseModal(modalConfig);
            verifyResponseConf.hasPopped = true;
            return false;
        } else {
            $.modal.close();
        }
    }  else {
        $.modal.close();
    }// defined in modal_question_retry

    $('#pixel_container').load('/process', postData, function() {
        // if we have the info we need in the response, send data to SSD
        var avtResponse = $('input#avt_lead_response').val();
        var lcResponseValue = $('input#avt_lccampaign_response').val();

        if (lcResponseValue == '0.00' && avtResponse == 'success' && allowContinuation === true) {
            adnContinuationCallback();
            return true;
        }

        if (avtResponse == 'success') {
            adnSuccessCallback();
        } else {
            if (displaySorryPage(verifyResponseConf.displayAlternateInvalidValidators) === true) {
                adnSorryCallback();
            } else {
                adnInvalidCallback();
            }
        }
    });
};

var adnSuccessCallback = function() {
    // Function needs to be defined in site that calls this file
    // Is called after a successful lead response, possibly to alter CSS or page flow
};

var adnContinuationCallback = function() {
    window.location = '/continue';
};

var adnInvalidCallback = function() {
    window.location = '/pending';
};

var adnSorryCallback = function() {
    $('#sorryresponse').css('display', 'block');
};

var determineCampaignKey = function(config) {
    var weightSum = 0;
    $.each(config, function(key, params) {
        weightSum = weightSum + parseInt(params.weight, 10);
    });
    var targetNumber = Math.floor((Math.random()*weightSum)+1);
    var targetCampaign = null;
    var maxTargetPercentage = 0;

    $.each(config, function(key, params) {
        if (targetCampaign === null) {
            maxTargetPercentage = maxTargetPercentage + parseInt(params.weight, 10);
            if (targetNumber <= maxTargetPercentage) {
                targetCampaign = params.campaign;
            }
        }
    });

    return targetCampaign;
};

var processFinal = function() {
    var avtResponse = $('input#avt_lead_response').val();
    var lcResponseValue = $('input#avt_lccampaign_response').val();

    if (avtResponse == 'success') {
        adnSuccessCallback();
    } else {
        if (displaySorryPage(verifyResponseConf.displayAlternateInvalidValidators)) {
            adnSorryCallback();
        } else {
            adnInvalidCallback();
        }
        return false;
    }
};

var processPostToLeads = function() {
    if (postedToLeads === false) {
        adnPostToLeads();
    }
};

$(document).ready(function() {
    // Initialize validation Engine on form elemetns
    $('#main-form').validationEngine({'showOneMessage' : true, 'promptPosition' : 'topLeft'});

    // set tabification
    $('#section-nav').tabify();


    // link at bottom will now scroll smoothly
    $('#bottom-cta').localScroll();

    $('#payroll_Y').click(function() {
        $('#p_stillworking').val('no');
    });

    $('#payroll_N').click(function() {
        $('#p_stillworking').val('yes');
    });

    // get the states poulated
    populateStateList();

    // setup auto tab if non-mobile
    if ($('html').hasClass('no-touch')) {
        $('#phone_area, #phone_prefix, #phone_suffix').autotab_magic();
    }

    var suggestionLink = $('#email-suggestion a').first();
    var suggestionContainer = $('#email-suggestion').first();

    suggestionLink = $('<a href="#"></a>').click(function(){
        $('#email').val(suggestionLink.text());
        suggestionContainer.hide();
        $('#email').trigger('change');
        return false;
    }).appendTo(suggestionContainer);

    $('#email').on('blur', function() {
        $(this).mailcheck({
            suggested: function(element, suggestion) {
                suggestionLink.text(suggestion.full);
                suggestionContainer.fadeIn('fast');
            },
            empty: function() {
                suggestionContainer.fadeOut('fast');
            }
        });
    });

    $('.mailcheck-selection').click(function() {
        $('#email').val($(this).attr('data-email')).attr('data-mailcheck-disable', 'true');
        $.modal.close();
        $('#main-form').submit();
        return false;
    });


    $('#main-form').submit(function(){
        var emailField = $('#email');
        // Before we run the validation, let's trim the email field
        // so any spaces before and after won't cause the validation
        // to fail)
        var myEmailFieldVal = emailField.val();
        // $.trim(emailField.val());
        emailField.val($.trim(myEmailFieldVal));

        var mySubmitButton = $('#submitbutton');

        mySubmitButton.attr('disabled','disabled');
        mySubmitButton.html('<img src="//d1fd9shixcplvx.cloudfront.net/sites/af5e01cf/img/processing-ellipses.gif">Please wait while we process your request<img src="//d1fd9shixcplvx.cloudfront.net/sites/af5e01cf/img/processing-ellipses.gif">');

        if (!$('#main-form').validationEngine('validate')) {
            mySubmitButton.attr('disabled',false);
            mySubmitButton.text('Submit');
            return false;
        } else {
            // Timeout set to post form in 2 seconds if ga doesn't return
            setTimerForBackupPost();
            ga('send', 'event', 'Form Post', 'submit', {
                hitCallback: function() {
                    clearTimeout(formBackupPost);
                    processPostToLeads();
                }
            });
            return false;
        }
    });



    $('#zipCode').change(function() {
        var zipCode = $(this).val();
        if ($('#zipCode').validationEngine('validate')) {
            return false;
        }
        if (zipCode.length == 5) {
            var ajaxOptions = {
                url: '/zipcode?zip=' + zipCode,
                dataType: 'json',
                success: function(data) {
                    if (data.city && data.state) {
                        gaTrackEvent('Zipcode', 'Success', zipCode);
                        // Since we got a state and city back, match the state in the residence state list
                        // select it and populate the city list
                        var states = [];
                        $('#residenceStateId option').each(function(){
                            states.push($(this).val());
                        });
                        var state;
                        if (data.state_short) {
                            state = data.state_short;
                        } else {
                            state = getStateAbbr(data.state);
                        }

                        if ($.inArray(state, states)) {
                            // This will populate the city dd list
                            cityOptionValue = '#residenceCity option[value="' + data.city + '"]';
                            $('#residenceStateId').val(state).trigger('change');
                            if (allowHideStateAndZipDD) {
                                $('#residenceStateAndZip').css('display','none');
                            }
                            $('#validationErrors').css('display','none');
                        } else {
                            adnShowCityStateWithError();
                        }
                    } else {
                        gaTrackEvent('Zipcode', 'Failure', zipCode);
                        adnShowCityStateWithError();
                    }
                },
                error: function() {
                    gaTrackEvent('Zipcode', 'Error');
                    adnShowCityStateWithError();
                }
            };
            $.ajax(ajaxOptions);
        }
    });

    $('footer a').click(function(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        var day = new Date();
        var id = day.getTime();
        window.open($(this).attr('href'), id, 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=400,height=300,left = 500,top = 500');
        return false;
    });

    if ($('#residenceStateId :selected').val()) {
        if ($('#residenceCity :selected').text()) {
            cityOptionValue = '#residenceCity option[value="' + $('#residenceCity :selected').text() + '"]';
        }
        $('#residenceStateId').trigger('change');
    }

    if ($('#residenceCity :selected').val()) {
        cityOptionValue = '#residenceCity option[value="' + $('#residenceCity :selected').text() + '"]';
        $('#residenceStateId').trigger('change');
    }

    if ($('#zipCode').val() > 0) {
        $('#zipCode').trigger('change');
    }


});




